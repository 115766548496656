import React from "react";

import { useStore } from "../../../../../context/state";
import Link from "next/link";
import "./CustomFirstSlide.module.scss";

export const CustomFirstSlide = (props) => {
  const { image, imageMobile, isEnabled, video, url, videoMobile, backgroundColor } = props;
  const { isMobile, mediaPath } = useStore();

  if (
    (!imageMobile && !image && !video && !videoMobile) ||
    !isEnabled
  ) {
    return null;
  }

  if (video || videoMobile) {
    return (
      <div
        className={"slide-custom u-relative light u-relative u-sizeFull u-borderRadius3"}
        style={{
          backgroundColor: backgroundColor || "",
        }}
      >
        <iframe title="Promotional Banners" src={(isMobile && videoMobile ? videoMobile : video) + "?autoplay=1&loop=1&autopause=0&muted=1"} width="100%" height="500" frameborder="0" allow="autoplay; fullscreen" allow="autoplay" allowfullscreen></iframe>
        <Link  href={url || "#"} prefetch={false}>
            <a className="u-absolute u-left0 u-right0 u-top0 u-bottom0"></a>
          </Link>
      </div>
    )
  }

  if (isMobile) {
    return (
      <div
        className={"slide-custom light u-relative u-sizeFull u-borderRadius3"}
        style={{
          backgroundColor: backgroundColor || "",
        }}
      >
      <Link href={url || "#"} prefetch={false}>
              <a
                className="bg bg-filter"
                style={{
                  backgroundImage: `url(${
                    mediaPath +
                    "" +
                    (imageMobile ||
                      image ||
                      "")
                  })`,
                  backgroundSize: "auto 100%",
                }}
              ></a>
      </Link>
      </div>
    );
  }

  return (
    <div
      className={"slide-vegas light u-relative u-sizeFull u-borderRadius3 " + (url ? "u-cursorPointer" : "")}
      style={{ backgroundColor: backgroundColor || "" }}
    >
    <Link href={url || "#"} prefetch={false}>
            <a
              className="bg bg-filter"
              style={{
                backgroundImage: `url(${
                  mediaPath +
                  "" +
                  (image ||
                    imageMobile ||
                    "")
                })`,
                backgroundSize: "auto 100%",
              }}
            ></a>
      </Link>
    </div>
  );
};
